import React from "react";
import { graphql } from 'gatsby'
import Layout from "../shared/components/organisms/layout/layout";
import ContactContent from "../components/organisms/contact-content/contact-content";
import useMobie from '../shared/hooks/useMobile'
import SEO from '../shared/components/atoms/seo/seo'
import '../shared/styles/index.scss';

// import SEO from "../shared/components/atoms/seo/seo"

const ContentPage = (props) => {

  // send active modal to layout
  const [modalActive, setModalActive] = React.useState(null);
  const isMobile = useMobie();

  const contactInfo = props.data.allContentfulPetysContactanos.nodes[0];
  const footerInfo = props.data.allContentfulPetysHome.nodes[0].menu;

  return (
    <Layout modalActive={modalActive}>
      <SEO
        title={contactInfo.metaTitulo ? contactInfo.metaTitulo : ''}
        description={contactInfo.metaDescripcion ? contactInfo?.metaDescripcion?.metaDescripcion : ''}
        slug={contactInfo.slug ? contactInfo.slug : ''}
        lang="ES"
      />
      <ContactContent
        contactInfo={contactInfo}
        footerInfo={footerInfo}
        setModalActive={setModalActive}
        isMobile={isMobile}
      />
    </Layout>
  )
}

export const query = graphql`
query ContactQuey {
  allContentfulPetysContactanos {
    nodes {
      titulo
      metaTitulo
      slug
      metaDescripcion {
        metaDescripcion
      }
      informacionPrincipal {
        json
      }
      checksDePoliticas {
        idCategoria
        descripcionRecomendadosCategorias {
          json
        }
      }
      informacionDeContacto {
        json
      }
    }
  }
  allContentfulPetysHome {
    nodes {
      menu {
        redesSocialesFacebook
        redesSocialesFacebookImagen {
          title
          description
          file {
            url
          }
        }
        redesSocialesInstagram
        redesSocialesInstagramImagen {
          title
          description
          file {
            url
          }
        }
        redesSocialesYoutube
        redesSocialesYoutubeImagen {
          title
          description
          file {
            url
          }
        }
      }
    }
  }
}
`

export default ContentPage
