import React from 'react'
import LayoutModal from '../layout-modal/layout-modal'
import Button from '../../atoms/button/button'
import './modal-generic.scss'

const ModalGeneric = props => {

  const { onClose, title, text, icon, className, type, buttonConfirm = false, onConfirm, ...params } = props

  React.useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "initial"
    }
  }, [])

  return (
    <LayoutModal onClose={onClose}>
      <div className={'f-modal-content ' + (className ? className : '')} {...params}>

        <i className="fal fa-times f-close-button" onClick={onClose}></i>

        {type === 'success' && <i className="icon-Check f-icon-success"></i>}

        {type === 'warning' && <i className="fal fa-exclamation-triangle f-icon-warning"></i>}

        {icon && icon}

        <h2 className="f-title--purple">{title}</h2>
        <p className="f-text-regular">{text}</p>

        {buttonConfirm &&
          <Button
            className="f-primary-purple f-button-action f-button"
            typeButton="button"
            title={buttonConfirm}
            onClick={onConfirm}
          />
        }

      </div>
    </LayoutModal>
  )
}

export default ModalGeneric
