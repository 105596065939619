import React from 'react'
import { FormControl, InputLabel } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { latoRegular } from '../../../styles/variables.scss';

const CustomLabel = withStyles((theme) => ({
  root: {
    color: '#5D5D5D',
    fontFamily: latoRegular,
    fontSize: 16,
    marginLeft: 10,
  },
}))(InputLabel);

const styles = createStyles({
  textareaAutosize: {
    // minWidth: 300,
    marginTop: 20,
    color: '#818181CC',
    fontFamily: latoRegular,
    fontSize: 16,
    borderRadius: 23,
    border: '1px solid #E8E8E8',
    background: '#F8F8F880',
    padding: '15px 20px 30px',
  },
  label: {
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: '10px 20px',
    background: '#F8F8F880',
    color: '#7E7E7E',
    fontFamily: latoRegular,
    fontSize: 12,
  }
});

const CustomInputTextArea = (props) => {
  const { label, id, fullWidth, reference, maxWrite, error, ...params } = props
  return (
    <FormControl
      fullWidth={fullWidth}
    >
      {label &&
        <CustomLabel shrink htmlFor={id} focused={false}>
          {label}
        </CustomLabel>
      }
      <textarea
        style={styles.textareaAutosize}
        className={(error ? 'error' : '')}
        id={id}
        ref={reference ? reference : null}
        {...params}
      >
      </textarea>
      {maxWrite && <span style={styles.label}>{maxWrite}</span>}
    </FormControl>
  )
}


export default CustomInputTextArea;
