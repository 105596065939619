import React, { useEffect } from "react"
import BreadCrumb from "../../../shared/components/atoms/breadcrumb/breadcrumb"
import RichText from "../../../shared/components/atoms/rich-text/rich-text"
import ImgContactanos from "../../../assets/images/img-contactanos.png"
import CustomInputText from "../../../shared/components/atoms/inputText/InputText"
import CustomInputTextArea from "../../../shared/components/atoms/inputTextArea/InputTextArea"
import CustomCheckboxNative from "../../../shared/components/atoms/custom-checkbox-native/custom-checkbox-native"
import DataPolicy from "../../../shared/components/atoms/data-policy/data-policy"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Button from "../../../shared/components/atoms/button/button"
import { useForm } from "react-hook-form"
import ModalGeneric from "../../../shared/components/modals/modal-generic/modal-generic"
import * as endpoints from "../../../shared/constants/apiConstants"
import homeLink from "../../../shared/utils/siteUrl"
import "./contact-content.scss"
import Helmet from "react-helmet"

const ContactContent = props => {
  const { isMobile, contactInfo, setModalActive, footerInfo } = props

  const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/
  const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  const { register, formState, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  useEffect(() => {
    let url_string = window.location.href
    let url = new URL(url_string)
    let section = url.searchParams.get("c")
    if (section === "y") {
      openSuccessModal()
    }

    setInterval(timestamp, 500)
  }, [])

  const timestamp = () => {
    var response = document.getElementById("g-recaptcha-response")
    if (response == null || response.value.trim() == "") {
      var elems = JSON.parse(
        document.getElementsByName("captcha_settings")[0].value
      )
      elems["ts"] = JSON.stringify(new Date().getTime())
      document.getElementsByName("captcha_settings")[0].value = JSON.stringify(
        elems
      )
    }
  }

  /*Modal Success*/

  const closeSuccessModal = () => setModalActive(null)

  const openSuccessModal = () =>
    setModalActive(
      <ModalGeneric
        title="Gracias por contactarnos"
        text="Tu solicitud ha sido enviada correctamente"
        type="success"
        onClose={closeSuccessModal}
      />
    )

  return (
    <>
      <Helmet>
        <meta HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8" />
        <script src="https://www.google.com/recaptcha/api.js"></script>
      </Helmet>
      <section className="f-contact-section">
        <div className="f-breadcrum-position">
          <BreadCrumb mobile={isMobile} />
        </div>

        <div className="f-contact-rich-header">
          {contactInfo && (
            <RichText
              className="f-article-body"
              jsonInfo={contactInfo.informacionPrincipal.json}
            />
          )}
        </div>

        <div className="f-contact-content">
          <form
            className="f-contact-form"
            autoComplete="off"
            action={endpoints.WebToCaseUrl}
            method="POST"
          >
            <input
              type="hidden"
              id="00N3j00000GUNs8"
              name="00N3j00000GUNs8"
              value={process.env.COUNTRY_NAME.toUpperCase()}
            />
            <input
              type="hidden"
              name="captcha_settings"
              value={`{"keyname":"Petys","fallback":"true","orgId":"${endpoints.ORGID}","ts":""}`}
            />
            {/* <!-- Estos campos siempre debe ir en todos los formularios --> */}
            <input
              type="hidden"
              ref={register()}
              name="retURL"
              value={homeLink}
            />
            <input
              type="hidden"
              ref={register()}
              name="orgid"
              value={endpoints.ORGID}
            />
            <input
              type="hidden"
              ref={register()}
              name="recordType"
              value={endpoints.RECORDTYPE}
            />
            <input
              type="hidden"
              ref={register()}
              name="00N3j00000GUT2O"
              id="00N3j00000GUT2O"
              maxLength="255"
              size="20"
              value={homeLink + "contactanos/"}
            />
            <input type="hidden" ref={register()} name="Origin" value="Site" />
            {/* <!-- <label for="status">Status</label> --> */}
            <select
              id="status"
              ref={register()}
              name="status"
              type="hidden"
              hidden
            >
              <option value="GRF_PendienteTipificacion">
                GRF_PendienteTipificacion
              </option>
            </select>
            {/* <!-- Marca: --> */}
            <select ref={register()} name="GRF_Marca__c" title="Marca" hidden>
              <option value="Petys">Petys</option>
            </select>
            {/* <!-- hasta aqui los campos que siempre debe ir en todos los formularios --> */}

            <div className="f-container-input">
              <CustomInputText
                fullWidth={true}
                label="Nombre y apellido"
                id="name-input"
                inputProps={{
                  name: "name",
                  maxLength: 100,
                  placeholder: "Laura Castro",
                }}
                inputRef={register({
                  required: true,
                  maxLength: 100,
                  pattern: nameRegex,
                })}
                error={errors.name ? true : false}
                errorText={errors.name ? "Campo inválido" : ""}
              />
            </div>

            <div className="f-container-input">
              <CustomInputText
                fullWidth={true}
                label="Correo electrónico"
                id="email-input"
                inputProps={{
                  name: "email",
                  maxLength: 100,
                  placeholder: "LauraCastro@gmail.com",
                }}
                inputRef={register({
                  required: true,
                  maxLength: 100,
                  pattern: mailRegex,
                })}
                error={errors.email ? true : false}
                errorText={errors.email ? "Campo inválido" : ""}
              />
            </div>

            {/* <div className="f-container-input">
              <CustomInputText
                fullWidth={true}
                label="Teléfono"
                id="phone-input"
                inputProps={{
                  name: "phone",
                  maxLength: 40,
                }}
                inputRef={register({
                  required: true,
                  maxLength: 40,
                })}
                error={errors.phone ? true : false}
                errorText={errors.phone ? "Campo inválido" : ""}
              />
            </div> */}

            {/* <div className="f-container-input f-select-container">
              <div className="f-select f-contact-select">
                <select
                  defaultValue="COLOMBIA"
                  ref={register()}
                  name="GRF_Pais__c"
                  id="GRF_Pais__c"
                  title="País"
                >
                  <option disabled value="">
                    Selecciona un país
                  </option>
                  <option value="COLOMBIA">COLOMBIA</option>
                  <option value="ECUADOR">ECUADOR</option>
                  <option value="PERU">PERU</option>
                  <option value="REPÚBLICA DOMINICANA">
                    REPÚBLICA DOMINICANA
                  </option>
                  <option value="BOLIVIA">BOLIVIA</option>
                  <option value="PUERTO RICO">PUERTO RICO</option>
                  <option value="PARAGUAY">PARAGUAY</option>
                  <option value="ARGENTINA">ARGENTINA</option>
                  <option value="CHILE">CHILE</option>
                </select>
              </div>
            </div> */}

            <div className="f-container-input f-select-container">
              <div className="f-select f-contact-select">
                <select
                  defaultValue=""
                  ref={register({
                    required: true,
                    maxLength: 100,
                    // pattern: nameRegex
                  })}
                  name="00N3j00000GUNsa"
                  id="00N3j00000GUNsa"
                  title="Tipo de identificación"
                >
                  <option disabled value="">
                    Tipo de identificación
                  </option>
                  <option value="CC">CC</option>
                  <option value="CE">CE</option>
                  <option value="NIT">NIT</option>
                </select>
              </div>
            </div>
            <div className="f-container-input">
              <CustomInputText
                fullWidth={true}
                label="Número de identificación:"
                id="number-id-input"
                inputProps={{
                  name: "00N3j00000GUNs5",
                  maxLength: 40,
                  placeholder: "11111",
                }}
                inputRef={register({
                  required: true,
                  maxLength: 40,
                })}
                error={errors.identification ? true : false}
                errorText={errors.identification ? "Campo inválido" : ""}
              />
            </div>
            <div className="f-container-input f-select-container">
              <div className="f-select f-contact-select">
                <select
                  defaultValue=""
                  ref={register({
                    required: true,
                    maxLength: 100,
                    // pattern: nameRegex
                  })}
                  name="type"
                  id="type"
                  title="type"
                >
                  <option disabled value="">
                    Selecciona un Asunto
                  </option>
                  <option value="ACTUALIZACION_DE_DATOS_PERSONALES">
                    Actualización datos personales
                  </option>
                  <option value="AGRADECIMIENTO">Agradecimiento</option>
                  <option value="GENERAL">Quejas</option>
                  <option value="SOLICITUD_INFORMACION">
                    Solicitud de información y dudas
                  </option>
                  <option value="SUGERENCIA">Sugerencia</option>
                  <option value="RECLAMO">Otros</option>
                </select>
              </div>
            </div>

            {/* <div className="f-container-input">
            <CustomInputText
              fullWidth={true}
              label="Asunto"
              id="subject-input"
              inputProps={{
                name: "subject", maxLength: 100, placeholder: 'Dudas sobre los pañitos'
              }}
              inputRef={register({
                required: true,
                maxLength: 100,
                // pattern: nameRegex
              })}
              error={errors.subject ? true : false}
              errorText={errors.subject ? "Campo inválido" : ''}
            />
          </div> */}

            <div className="f-container-input">
              <CustomInputTextArea
                fullWidth={true}
                rows={9}
                label="Descripción"
                name="description"
                id="commentary"
                autoFocus={true}
                placeholder="Escribe aquí..."
                maxWrite=""
                reference={register({
                  required: true,
                  maxLength: 300,
                  // pattern: nameRegex
                })}
              />
            </div>
            {contactInfo?.checksDePoliticas.map(
              ({ idCategoria, descripcionRecomendadosCategorias }, index) => {
                return (
                  <div className="f-row" key={index}>
                    <div className="f-container-input last f-check-terms-container">
                      <CustomCheckboxNative
                        className="f-check-terms"
                        label={documentToReactComponents(
                          descripcionRecomendadosCategorias.json
                        )}
                        value="1"
                        id={idCategoria}
                        name={idCategoria}
                        inputRef={register({
                          required: "Este campo es requerido",
                        })}
                        error={errors[idCategoria] ? true : false}
                        errorText={
                          errors[idCategoria]
                            ? errors[idCategoria].message
                              ? errors[idCategoria].message
                              : "Campo inválido"
                            : ""
                        }
                      />
                    </div>
                  </div>
                )
              }
            )}
            <DataPolicy />
            <div
              className="g-recaptcha"
              data-sitekey="6LeGt5UaAAAAAAxi9dOyhqwya2OATZacd_IAvnfm"
            ></div>
            <Button
              className="f-primary-purple f-button-send"
              typeButton="button"
              type="submit"
              title="Enviar"
              disabled={!formState.isValid}
            />
          </form>

          <span className="f-separator"></span>

          <div className="f-contact-content-aside">
            <figure className="f-contact-img">
              <img src={ImgContactanos} alt="Contactanos imagen" />
            </figure>

            <div className="f-contact-rich-info">
              {contactInfo.informacionDeContacto && (
                <RichText
                  className="f-contact-rich-container"
                  jsonInfo={contactInfo.informacionDeContacto.json}
                />
              )}
              <p className="f-redes-container">
                Contactar por medio de:
                <a target="_blank" href={footerInfo.redesSocialesInstagram}>
                  <i className="fab fa-instagram"></i>
                </a>
                <a target="_blank" href={footerInfo.redesSocialesFacebook}>
                  <i className="fab fa-facebook-f"></i>
                </a>
                {/* <a href={footerInfo.redesSocialesYoutube}>
                <i className="fab fa-youtube"></i>
              </a> */}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactContent
