import React from 'react'
import Button from '../button/button'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import './rich-text.scss'

const RichText = props => {

  const { jsonInfo, className, ...params } = props;
  const optionDescription = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => {
        if (next !== '' && next !== null) {
          return <p>{next}</p>
        } else {
          return null
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        if (node.data.target.fields) {
          return (
            <img className="f-rich-img" src={node.data.target.fields.file['es-CO'].url} alt={node.data.target.fields.description['es-CO']} />
          )
        } else {
          return null
        }
      },
      [BLOCKS.PARAGRAPH]: (node, next) => {
        if (next && next[0] !== '' && next[0] !== ' ') {
          return <p>{next}</p>
        }
      },
      [INLINES.HYPERLINK]: (node, next) => <Button className={"f-link"} rel="noopener noreferrer" title={next} href={node.data.uri} />
      // <a href={node.data.uri} rel="noopener noreferrer" target="_blank">{next}</a>
    },
  }

  return (
    <div className={"rich-text-container " + (className ? className : '')} {...params}>
      {
        documentToReactComponents(jsonInfo && jsonInfo, optionDescription)
      }
    </div >
  )
}

export default RichText;
